<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="detail" service="mortgage" backlink="/profile" />
      <div class="content">
        <div class="content__container mobile_center">
          <p class="promo promo_blocks">{{ "s2p3_text" | localize }}</p>
          <div class="service__container">
            <button
              v-for="(type, k) in [1,2,3]" :key="k"
              class="service service_realty"
              :class="'service_realty_' + type"
              @click="saveAndGo(type)"
            >
              <div class="service__title">
                {{ "s2p3_block_" + type + "_title" | localize }}
              </div>
              <div class="service__subtitle">
                {{ "s2p3_block_" + type + "_subtitle" | localize }}
              </div>
            </button>
          </div>

          <div class="error__container">
            <p class="error" v-if="error">{{ error | localize }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import { saveMixin } from '@/mixins/saveMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Mortgage_realty',
  components: { Backlink },
  mixins: [saveMixin, commonMixin],
  data: () => ({ error: null }),
  created () { 
    // Установка количества шагов для услуги
    this.setAllSteps('mortgage', 36)
  },
  methods: {
    saveAndGo (type) {
      const droppedSteps = [
        '/mortgage/sell-and-buy',
        '/mortgage/already-sold',
        '/mortgage/old-realty-price',
        '/mortgage/old-realty-mortgage',
        '/mortgage/sell-during',
        '/mortgage/help',
        '/mortgage/old-realty-price-not-sold',
        '/mortgage/old-mortgage-not-sold-realty',
        '/mortgage/old-mortgage-not-sold-price',
        '/mortgage/self-money',
      ]

      // Сброс следующих шагов линии действий
      this.dropSteps('mortgage', this.$router.currentRoute.path, droppedSteps[0])
            
      let nextLink = '/'
      switch (type) {
        case 1: 
          nextLink = '/mortgage/banks'
          this.addMissedSteps('mortgage', droppedSteps)
          break
        case 2:
          nextLink = '/mortgage/already-sold'
          this.addMissedSteps('mortgage', ['/mortgage/sell-and-buy'])
          break
        case 3:
          nextLink = '/mortgage/sell-and-buy'
          break
      }
      this.saveServiceInfo('mortgage', nextLink, { type: type })
    }
  }
}
</script>
